const dot = require("dot-object");
import React, { useState } from "react";
import {
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  Box,
} from "@mui/material";
import hooks from "./hooks";
import parser from "./parser";

function View({ question, context }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: {
      login: "",
      code: "",
    },
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [codeSentMessage, setCodeSentMessage] = useState();

  context.assign({
    session: undefined,
    admin: false,
  });

  const validate = (name, value) => {
    if (value === "") {
      name === "output.code"
        ? setFormErrors({ code: "Access code is Required" })
        : setFormErrors({ login: "Email or Telephone is Required" });
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCodeSentMessage(null);

    let values = { ...formValues };
    dot.str(name, value, values);
    setFormValues(values);
    validate(name, value);
    name != "output.code"
      ? setIsDisabled(methodFromLogin(value) === "letter_id")
      : null;
    setFormErrors({});

    if (name === "output.code" && value.length === e.target.maxLength) {
      requestToken(values.output);
    }
  };

  //
  const methodFromLogin = (login) => {
    if (login && login.length >= 10 && login.match(/^[-+ 0-9]+$/i) !== null) {
      return "sms";
    } else if (login && login.indexOf("@") > -1) {
      return "email";
    } else {
      return "letter_id";
    }
    // TODO: task_id, for MTurk Taskers
  };

  const requestCode = (login) => {
    const item = parser.parse(question.input.data, context);

    let params = {
      mode: "LIVE",
      item: item,
    };

    if (!params.item.metadata) {
      params.item.metadata = {};
    }

    if (params.item.foreign_id === "") {
      delete params.item.foreign_id;
    }

    params.item[methodFromLogin(login)] = login;
    params.item.metadata.target_url = `${window.location.origin}${item.metadata.target_url}`;

    hooks
      .gql(
        {
          // TODO: validation
          query:
            "mutation($mode:Mode $item:NewToken!) { createToken(mode:$mode item:$item) { created_at } }",
          variables: params,
        },
        context
      )
      .then(() => {
        setCodeSentMessage(question.properties.locale.receive_code);
      });
  };

  const requestToken = (args) => {
    let params = {
      mode: "LIVE",
      code: args.code,
    };
    params[methodFromLogin(args.login)] = args.login;

    hooks
      .gql(
        {
          // TODO: validation
          query:
            "query($mode:Mode $code:ID! $email:EmailAddress $sms:Telephone $letter_id:ID) { token(mode:$mode code:$code email:$email sms:$sms letter_id:$letter_id) { access_token id_token } }",
          variables: params,
        },
        context
      )
      .then((res) => {
        res.errors ? setFormErrors({ code: res.errors[0].message }) : null;
        context.assign({
          session: Object.assign(
            {
              token_type: "Token",
            },
            res.data.token
          ),
          admin: false,
        });
      });
  };

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 6 }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: 1, marginRight: "15px" }}>
          <TextField
            id="login"
            name="output.login"
            label={question.properties.locale.login}
            variant="filled"
            value={formValues.output.login}
            onChange={handleInputChange}
            required
            autoFocus
            inputProps={{
              minLength: 5,
            }}
            autoComplete="off"
            aria-describedby="login-helper-text"
            fullWidth
          />
          <FormHelperText
            id="code-helper-text"
            style={{ fontSize: "0.9rem", minHeight: "2em", color: "#f44336" }}
          >
            {codeSentMessage ? codeSentMessage : formErrors.login}
          </FormHelperText>
        </Box>

        <Box sx={{ display: "flex", marginBottom: "38px", marginTop: "5px" }}>
          <Button
            className={
              isDisabled
                ? "request-code-button-disabled"
                : "request-code-button"
            }
            type="button"
            onClick={() => requestCode(formValues.output.login)}
            fullWidth
            disabled={isDisabled}
          >
            Request Code
          </Button>
        </Box>
      </Box>

      <FormControl error fullWidth>
        <TextField
          id="code"
          name="output.code"
          label={question.properties.locale.code}
          variant="filled"
          value={formValues.output.code}
          onChange={handleInputChange}
          required
          inputProps={{
            maxLength: 6,
          }}
          autoComplete="off"
          aria-describedby="code-helper-text"
        />
        <FormHelperText id="code-helper-text" style={{ fontSize: "0.9rem" }}>
          {formErrors.code}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
}

export default View;
